const myMixin = {
  data() {
    return {
      modalAlertShow: false
    };
  },
  created: function () {
    this.hello();
  },
  methods: {
    hello: function () {
      console.log("hello from mixin!");
    }
  }
};
export default myMixin;
